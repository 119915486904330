import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import ReactDOM from 'react-dom';

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";
import AnimatedComponent from "../../components/AnimatedComponent";
import Join from '../../components/join/Join';

import CareerTabs from "../../components/careerTabs/CareerTabs";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './careers.css';

const Careers = () => {

  const baseUrl = "https://backend.abrecogroup.com/storage/";
  const [career, setCareerSlider] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const careerApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.abrecogroup.com/api/career"
        );

        const career = data.data.career || {};
        setCareerSlider(career);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    careerApi();
  }, []);

  if (loading) {
    return <div></div>;
  }

  const itemsa = [
    <div className="item">
      <img src={baseUrl + career.banner_image} alt="Abreco Group" />
      <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: career.title }}></h2></div>
    </div>
  ];

  const responsivea = {
    0: { itemsa: 1 },
    568: { itemsa: 1 },
    1024: { itemsa: 1 },
    1200: { itemsa: 1 },
    1368: { itemsa: 1 },
  };

  return (
    <>
    {career && (
      <Helmet key={career.uuid}>
        {career.seo_title !== null && <title>{career.seo_title}</title>}
        {career.seo_description !== null && (
          <meta name="description" content={career.seo_description} />
        )}
        {career.seo_keyword !== null && (
          <meta name="keywords" content={career.seo_keyword} />
        )}
      </Helmet>
    )}

      <section className='siteBanner'>
        <AliceCarousel
          items={itemsa}
          responsive={responsivea}
          disableDotsControls
          disableButtonsControls
        />
        <div className='slanding1'>
          <SimpleAnimatedComponent animation="animate__fadeInUpBig">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
        <div className='slanding2'>
          <SimpleAnimatedComponent animation="animate__fadeInUpBig">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
      </section>

      <section className='pageSubTitle'>
        <div className='container'>
          <div className='data' data-aos="fade-up">
            <div id="dot-1">

              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div>
            {/* <AnimatedComponent animation="animate__fadeInUp" delay=".1s" duration="1s"> */}
              {career?.page_title ? (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: career.page_title }}></h2>
                  <p dangerouslySetInnerHTML={{ __html: career.page_content }}></p>
                </>
              ) : (
                <p></p>
              )}
            {/* </AnimatedComponent> */}
          </div>
        </div>
      </section>

      <section className='pageSubTitle pb-0'>
        <div className='container'>
          <div className='data no-padding' data-aos="fade-up">
              <p>Job</p>
              <h2>Openings</h2>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <CareerTabs />
        </div>
      </section>

      {/* <Join /> */}

      <Footer />

    </>
  )
}

export default Careers