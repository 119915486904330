import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './news.css';

const News = () => {

    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [newsdata, setNews] = useState({});
    const [newsList, setNewsList] = useState({});
    const [loading, setLoading] = useState(true);

    const [blogLists, setBlogLists] = useState([]);
    const [blogging, setBlogging] = useState([]);
    const navigate = useNavigate();
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        const newsdataApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/news"
                );

                const newsdata = data.data.news || {};
                setNews(newsdata);

                const newsList = data.data.news_list || {};
                setNewsList(newsList);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        newsdataApi();
    }, []);

    useEffect(() => {
        const blogMain = async () => {
            const { data } = await axios.get(
                "https://backend.abrecogroup.com/api/news"
            );
            const blogLists = data.data.news_list;
            setBlogLists(blogLists);
            const blogging = data.data.news_list;
            setBlogging(blogging);
        };
        blogMain();
        setContentLoaded(true);
    }, []);

    const handleBlogClick = (list) => {
        navigate(`/news/${list.slug}`, {});
    };


    if (loading) {
        return <div></div>;
    }

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const itemsa = [
        <div className="item">
            <img src={baseUrl + newsdata.image} alt="Abreco Group" />
            <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: newsdata.title }}></h2><span>{newsdata.sub_title}</span></div>
        </div>
    ];

    const responsivea = {
        0: { itemsa: 1 },
        568: { itemsa: 1 },
        1024: { itemsa: 1 },
        1200: { itemsa: 1 },
        1368: { itemsa: 1 },
    };

    return (
        <>
            {/* PAGE BANNER */}
            <section className='siteBanner'>
                <AliceCarousel
                    items={itemsa}
                    responsive={responsivea}
                    disableDotsControls
                    disableButtonsControls
                />
                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>

            <section className='pageSubTitle'>
                <div className='container'>
                    <div className='data'>
                        <div id="dot-1">
                            <div className="dot-pattern-ten-six" data-aos="fade-up">
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            </div>
                        </div>
                        {newsdata?.title ? (
                            <>
                                <h2 dangerouslySetInnerHTML={{ __html: newsdata.page_title }} data-aos="fade-up"></h2>
                            </>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </section>

            <section className='pageTitle csr-activities'>
                <div className='container'>
                    <div className='data'>
                        <div className="dots dot-pattern-ten-six xl" data-aos="fade-up">
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        </div>
                        <p data-aos="fade-up">Recent Updates</p>
                        <h2 data-aos="fade-up">OUR <span>NEWS</span></h2>
                    </div>

                    <div className='locationsBoxes'>
                        {newsList.map((list, index) => (
                            <>
                                <div className='item' key={index} data-aos="fade-up">
                                    <div>
                                        <img src={baseUrl + list.image} alt="Abreco Group" />
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: list.title }}></p>
                                    <div onClick={() => handleBlogClick(list)} className="link-whole" rel="noopener noreferrer">CONTINUE</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />

            {newsdata && (
                <Helmet key={newsdata.uuid}>
                    {newsdata.seo_title !== null && <title>{newsdata.seo_title}</title>}
                    {newsdata.seo_description !== null && (
                        <meta name="description" content={newsdata.seo_description} />
                    )}
                    {newsdata.seo_keyword !== null && (
                        <meta name="keywords" content={newsdata.seo_keyword} />
                    )}
                </Helmet>
            )}
        </>
    )
}

export default News