import React, { useState, useEffect } from "react";
import axios from "axios";
import './join.css';
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import AnimatedComponent from '../AnimatedComponent';

import 'react-slidy/lib/index.scss'
import 'react-slidy/lib/styles.css'

import JoinImg from "../../images/join.png";
import JoinLeft from "../../images/join-left.webp";
import JoinRight from "../../images/join-right.webp";

const Join = () => {

    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [employeeTestimonials, setEmployeeTestimonials] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const employeeTestimonialsApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/home"
                );

                const employeeTestimonials = data.data.testimonials || {};
                setEmployeeTestimonials(employeeTestimonials);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        employeeTestimonialsApi();
    }, []);

    if (loading) {
        return <div></div>;
    }
    
    const items = employeeTestimonials.map((list, index) => (
        <div className="item" data-value={index} key={index}>
            <div className="tdata">
                <small dangerouslySetInnerHTML={{ __html: list.description }}></small><b>{list.order}</b>
                <a href="/life-at-abreco#abrecoTestimonials">read more</a>
            </div>
            <div className="timg">
                <img src={baseUrl + list.image} alt="Abreco Group"  />
            </div>
        </div>
    ));

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
        1200: { items: 1 },
        1368: { items: 1 },
    };

    return (
        <>
            <section className='joinBannerBg'>
                <div className='container'>
                    <div className='data' data-aos="fade-up">
                        <p>Careers</p>
                        <h2>ACCELERATE YOUR <br />CAREER WITH ABRECO</h2>
                        <Link to="/careers">JOB OPENING</Link>
                    </div>
                    <div className="lightdots dots dot-pattern-ten-six xl">
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                    </div>
                </div>
                <div className='slanding1'>
                    <AnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </AnimatedComponent>
                </div>
                <div className='slanding2'>
                    <AnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </AnimatedComponent>
                </div>
            </section>

            <section className='joinAbreco'>
                <div className='container'>
                    <div className='data'>
                        <div className='testimonialsWrapper'>
                            <p data-aos="fade-up">Why Join Abreco</p>
                            <h2 data-aos="fade-up">LEARN FROM <br />THE BEST</h2>
                            <div className="dots dot-pattern-ten-six">
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            </div>
                            <div className='testimonials' data-aos="fade-up">
                                <AliceCarousel
                                    mouseTracking
                                    items={items}
                                    autoPlay
                                    autoPlayInterval={5000}
                                    infinite
                                    disableDotsControls
                                    animationType="slide"
                                    animationEasingFunction="ease"
                                    autoPlayStrategy="none"
                                    stopOnHover={false}
                                    animationDuration={1000}  // Duration in milliseconds
                                    responsive={responsive}
                                    renderPrevButton={() => {
                                        return <img src={JoinLeft} className="heartbeat" alt="Abreco Group" />
                                    }}
                                    renderNextButton={() => {
                                        return <img src={JoinRight} className="heartbeat" alt="Abreco Group" />
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Join