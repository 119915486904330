import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const HomePageSubTitle = () => {

  const [pageSubTitle, setPageSubTitle] = useState({});
  const [seoData, setSEOData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pageSubTitleApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.abrecogroup.com/api/home"
        );

        const pageSubTitle = data.data.multifaceted || {};
        setPageSubTitle(pageSubTitle);

        const seoData = data.data.seo || {};
        setSEOData(seoData);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    pageSubTitleApi();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <>

      <section className='pageSubTitle'>
        <div className='container'>
          <div className='data'>
            <div id="dot-1">
              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div>
            {pageSubTitle?.title ? (
              <>
                <h2 dangerouslySetInnerHTML={{ __html: pageSubTitle.title }} data-aos="fade-up"></h2>
                <p dangerouslySetInnerHTML={{ __html: pageSubTitle.sub_title }} data-aos="fade-up"></p>
              </>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </section>

      {seoData && (
        <Helmet key={seoData.uuid}>
          {seoData.seo_title !== null && <title>{seoData.seo_title}</title>}
          {seoData.seo_description !== null && (
            <meta name="description" content={seoData.seo_description} />
          )}
          {seoData.seo_keyword !== null && (
            <meta name="keywords" content={seoData.seo_keyword} />
          )}
        </Helmet>
      )}

    </>
  )
}

export default HomePageSubTitle