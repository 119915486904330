import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import '../components/footer/footer.css';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const CareerDetails = () => {
    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [title, setTitle] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const urlParts = window.location.href.split("/");
    const blogSlug = urlParts[urlParts.length - 1];
    const navigate = useNavigate();

    const [career_list_id, setcareer_list_id] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [position, setPosition] = useState("");
    const [subBrand, setSubBrand] = useState("");
    const [brandName, setBrandNme] = useState("");
    
    const [message, setMessage] = useState("");
    const [resume, setResume] = useState(null);
    const [resumeError, setResumeError] = useState("");
    const [verified, setVerified] = useState(false);

    // New state for popup
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = `https://backend.abrecogroup.com/api/career-apply/${blogSlug}`;
                const { data } = await axios.get(apiUrl);
                const title = data.data.career_detail;
                setTitle(title);
                setSeoTitle(data.data.seo_title || '');
                setSeoDescription(data.data.seo_description || '');
                setSeoKeyword(data.data.seo_keyword || '');
                setPosition(title.title); // Set the position state here
                setSubBrand(title.sub_brand);
                setcareer_list_id(title.uuid);
            } catch (err) {
                setError('Failed to fetch data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
        
        // Show popup when component loads
        setShowPopup(true);

    }, [blogSlug]);

    const handleResumeChange = (event) => {
        let isValid = true;
        const file = event.target.files[0];
        setResume(file);
        if (event.target.files[0].size > 2000000) {
            setResumeError("PDF size should be less than 2 MB");
            isValid = false;
        } else {
            setResumeError("");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm() && verified) {
            setIsLoading(true);

            const recaptchaToken = await window.grecaptcha.execute('6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV', { action: 'submit' });

            const formObject = new FormData();
            formObject.append("career_list_id", career_list_id);
            formObject.append("name", name);
            formObject.append("email", email);
            formObject.append("phone", phone);
            formObject.append("position", position); // Ensure the position is included in the form data
            formObject.append("brand", subBrand); // Ensure the subBrand is included in the form data
            formObject.append("message", message);
            formObject.append("resume", resume);
            formObject.append("recaptchaToken", recaptchaToken);

            axios
                .post("https://backend.abrecogroup.com/api/career-form", formObject)
                .then((response) => {
                    event.target.reset();
                    const responseMsg = response.data.message;
                    if (responseMsg === "Successful") {
                        console.log(responseMsg);
                        window.location.href = "/thank-you-for-applying";
                    } else {
                        console.log(responseMsg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const validateForm = () => {
        let isValid = true;
        if (!verified) {
            isValid = false;
        }
        if (!name.trim()) {
            setNameError("Please enter your name.");
            isValid = false;
        } else {
            setNameError("");
        }
        if (!email.trim()) {
            setEmailError("Please enter your email address.");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Email is invalid");
            isValid = false;
        } else {
            setEmailError("");
        }
        if (!phone.trim()) {
            setPhoneError("Please enter your phone number.");
            isValid = false;
        } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
            setPhoneError("Phone is invalid");
            isValid = false;
        } else {
            setPhoneError("");
        }
        if (!resume) {
            setResumeError("Please upload your resume.");
            isValid = false;
        } else if (!/\.pdf$/.test(resume.name.toLowerCase())) {
            setResumeError("Please upload a PDF file");
            isValid = false;
        } else {
            setResumeError("");
        }
        return isValid;
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV">
            <>
                {showPopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <h2>Important Notice</h2>
                            <p>Abreco Group wants to alert candidates that third parties are from time to time fraudulently advertising vacancies at Abreco Group and/or offering employment contracts with subsequent payment instructions to accounts linked to the fraudulent activity.</p>
                            <p>Please note:</p>
                            <ul>
                                <li>Abreco Group will never request payment for an application or offer made.</li>
                                <li>All official vacancies are advertised on the Abreco Group website.</li>
                            </ul>
                            <p>Candidates are advised to visit our official website to confirm the validity of any vacancies.</p>
                            <p>Stay vigilant and protect yourself from potential scams. If you suspect fraudulent activity related to Abreco Group job postings, please contact us immediately.</p>
                            <button className="popup-close-button" onClick={closePopup}>CLOSE</button>
                        </div>
                    </div>
                )}

                <Helmet>
                    <style>
                        {`
                        .popup-overlay {
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.7);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                z-index: 1000;
                            }
                            .popup-content {
                                background-color: #fff;
                                padding: 20px 20px 55px;
                                border-radius: 8px;
                                width: 90%;
                                max-width: 50%;
                                text-align: left;
                                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
                                position: relative;
                                font-size: 14px;
                                color: #000000;
                            }
                            .popup-content h2 {
                                border-bottom: 1px solid #dbdbdb;
                                font-size: 20px;
                                font-weight: 600;
                                padding-bottom: 10px;
                                font-style: normal;
                                color: #ee2346;
                            }
                            .popup-content p {
                                margin-bottom: 5px;
                            }
                            .popup-content p, .popup-content ul li {font-size: 14px;line-height: 24px;}
                            .popup-content ul li {
                                list-style-type: disc;
                            }
                            .popup-close-button {
                                position: absolute;
                                bottom: 20px;
                                right: 20px;
                                background: none;
                                border: none;
                                font-size: 14px;
                                cursor: pointer;
                                background: #ee2346;
                                color: #ffffff;
                                padding: 3px 20px;
                                border-radius: 5px;transition: .5s;
                            }
                            .popup-close-button:hover {
                            background: #000000;
                            }
                        nav#site-nav,.mobileMenuWrapper,ul.fixed-navbar-nav,.scroll-to-top {display: none !important;}
                        nav#site-nav .navbar-brand img {width: 135px;position: relative;top: 0px;left: 0;}
                        nav#site-nav.fixedHeader .navbar-brand img {top: 0px;}
                        .fixed-navbar-nav {transform: translate(-50%, -50%) rotate(-90deg);transform-origin: unset;bottom: unset;top: 40%;}

                        .pageTitle.news.newsDetailsPage, body {background-color: #f8f8f8;margin: 0;}
                        .pageTitle.news.newsDetailsPage .container .data {justify-content: center;}
                        footer .container {padding-bottom: 0;}
                        .hasFile {margin-top:50px;}
                        .closePage {position: absolute;top: 0;right: 0;}
                        .closePage svg {font-size: 40px;color: #ee2346;}

                        @media (min-width: 1369px) and (max-width: 1600px) {
                        nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
                        }
                        @media (min-width: 1201px) and (max-width: 1368px) {
                        nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
                        }
                        @media (min-width: 993px) and (max-width: 1200px) {
                        nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
                        }
                        @media (max-width: 992px) {
                        nav#site-nav .navbar-brand, nav#site-nav.fixedHeader .navbar-brand {padding:0;}
                        nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 100px;top: 0;left: 0;}
                        .popup-content {width: 100%;max-width: 90%;}
                        }
                        @media (max-width: 600px) {
                        nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 90px;top: 0;left: 0;}
                        .popup-content {width: 100%;max-width: 95%;}
                        .popup-overlay {align-items: start;padding-top: 15px;}
                        }
                    `}
                    </style>
                </Helmet>

                <footer className='pageTitle news newsDetailsPage policy'>
                    <div className='container'>
                        <div className='data rowData'>
                            <div className='form' data-aos="zoom-in">
                                <div className="form-container">
                                    <h1>Apply Now</h1>
                                    <p style={{
                                        fontSize: '16px',
                                        lineHeight: '26px',
                                    }}>You are applying for the position of <span className="text-red">{title.title}</span> at <span className="text-red">{title.brand_name}</span>. Please fill out the form to join the Abreco family!</p>
                                    <a href="/careers" className="closePage">
                                        <IoCloseSharp />
                                    </a>
                                    <form onSubmit={handleSubmit}>

                                        <input type="hidden" id="career_list_id" name="career_list_id" value={title.uuid} onChange={(event) => setcareer_list_id(event.target.value)} />
                                        <div className="form-group">
                                            <input id="name" type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} name="name" placeholder=" " autoComplete="off" />
                                            <label htmlFor="name"> Name *</label>
                                            {nameError && <p className="error">{nameError}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input id="email" type="text" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} name="email" placeholder=" " autoComplete="off" />
                                            <label htmlFor="email"> Email Address *</label>
                                            {emailError && <p className="error">{emailError}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input id="phone" type="text" className="form-control" value={phone} onChange={(event) => setPhone(event.target.value)} name="phone" placeholder=" " autoComplete="off" />
                                            <label htmlFor="phone"> Phone Number *</label>
                                            {phoneError && <p className="error">{phoneError}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input id="position" type="hidden" className="form-control" value={position} onChange={(event) => setPosition(event.target.value)} name="position" placeholder=" " autoComplete="off" />
                                            <input id="subBrand" type="hidden" className="form-control" value={subBrand} onChange={(event) => setSubBrand(event.target.value)} name="subBrand" placeholder=" " autoComplete="off" />
                                        </div>
                                        <div className="form-group">
                                            <input id="resume" type="file" className="form-control border-0" onChange={handleResumeChange} name="resume" />
                                            <label htmlFor="resume"> Upload Resume * <span>(PDF only with maximum uploading size: 2MB)</span></label>
                                            {resumeError && <p className="error">{resumeError}</p>}
                                        </div>
                                        <div className="form-group">
                                            <textarea id="message" className="form-control" value={message} onChange={(event) => setMessage(event.target.value)} name="message" placeholder=" " autoComplete="off" />
                                            <label htmlFor="message"> Your Message </label>
                                        </div>
                                        <div className="form-group mb-3">
                                            <GoogleReCaptcha
                                                sitekey="6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV"
                                                onVerify={() => {
                                                    console.log("reCAPTCHA verified!");
                                                    setVerified(true);
                                                }}
                                            />
                                        </div>
                                        <div className="form-group text-left">
                                            <button className="submit-button" type="submit">
                                                {isLoading ? (
                                                    <>
                                                        <div className="btn-loader"></div> Request Verifying...
                                                    </>
                                                ) : (
                                                    "JOIN THE ABRECO FAMILY TODAY!"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <Helmet>
                    {title.seo_title && <title>{title.seo_title}</title>}
                    {title.seo_description && (
                        <meta name="description" content={title.seo_description} />
                    )}
                    {title.seo_keyword && (
                        <meta name="keywords" content={title.seo_keyword} />
                    )}
                </Helmet>
            </>
        </GoogleReCaptchaProvider>
    );
};

export default CareerDetails;