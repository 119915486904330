import React, { useState, useEffect } from "react";
import axios from "axios";
import AnimatedComponent from '../../components/AnimatedComponent';
import { Link } from "react-router-dom";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './clients.css';

const Clients = () => {

    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [ceoMessage, setCeoMessage] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const ceoMessageApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/home"
                );

                const ceoMessage = data.data.logo || {};
                setCeoMessage(ceoMessage);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        ceoMessageApi();
    }, []);

    if (loading) {
        return <div></div>;
    }

    const items = ceoMessage.map((list, index) => (
        <div className="item" key={index}>
            <Link to={list.button_link} target="_blank" data-aos="fade-up"><img src={baseUrl + list.logo} alt="Abreco Group" /></Link>
        </div>
    ));

    const responsive = {
        0: { items: 2 },
        568: { items: 2 },
        767: { items: 3 },
        1024: { items: 3 },
        1200: { items: 4 },
        1368: { items: 5 },
    };

    return (
        <AnimatedComponent animation="animate__fadeIn">
            <section className='clientsCarousel'>
                <div className='container'>
                    <div className='data'>
                        <AliceCarousel
                            mouseTracking
                            items={items}
                            autoPlay
                            autoPlayInterval={2000}
                            infinite
                            disableButtonsControls
                            disableDotsControls
                            responsive={responsive}
                        />
                    </div>
                </div>
            </section>
        </AnimatedComponent>
    )
}

export default Clients