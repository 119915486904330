import React, { useState, useEffect } from "react";

import HomeSlider from '../../components/homeSlider/HomeSlider';
import HomePageSubTitle from "../../components/HomePageSubTitle";
import HomePageSubTitleWithBanner from "../../components/HomePageSubTitleWithBanner";
import Brands from '../../components/brands/Brands';
import Join from '../../components/join/Join';
import Timeline from '../../components/timeline/Timeline';
import Ceo from '../../components/ceo/Ceo';
import News from '../../components/news/News';
import LifeAt from '../../components/lifeat/LifeAt';
import Clients from '../../components/clients/Clients';
import Locations from '../../components/locations/Locations';
import AnimatedComponent from '../../components/AnimatedComponent';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Footer from "../../components/footer/Footer";
const Home = () => {

  const [isContentLoaded, setContentLoaded] = React.useState(false);

  React.useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setContentLoaded(true);
    }, 2000); // Simulate 2 second delay for loading
  }, []);

  useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration (in milliseconds)
      });
  }, []);

  return (
    <>

      <HomeSlider />

      <HomePageSubTitle />

      <HomePageSubTitleWithBanner />

      {/* <Brands /> */}
      {isContentLoaded && <Brands />}

      {/* <Join /> */}
      {isContentLoaded && <Join />}

      {/* <Timeline /> */}
      {isContentLoaded && <Timeline />}

      {/* <Ceo /> */}
      {isContentLoaded && <Ceo />}

      {/* <News /> */}
      {isContentLoaded && <News />}

      {/* <LifeAt /> */}
      {isContentLoaded && <LifeAt />}

      {/* <Clients /> */}
      {isContentLoaded && <Clients />}

      {/* <Locations /> */}
      {isContentLoaded && <Locations />}

      {/* <Footer /> */}
      {/* <Footer initiallyHidden={true} /> */}
      {isContentLoaded && <Footer />}

    </>
  )
}

export default Home