import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Masonry from 'react-masonry-css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './gallery.css';

const Gallery = () => {
    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [bannerSlider, setLifeAtAbrecoSlider] = useState({});
    const [loading, setLoading] = useState(true);

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/gallery-setting"
                );

                setLifeAtAbrecoSlider(data.data.gallery_setting || {});

                const gallery = data.data.gallery || [];
                const formattedGalleryImages = gallery.map(image => ({
                    src: baseUrl + image.image,
                    caption: image.title || ''
                }));
                setGalleryImages(formattedGalleryImages);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div></div>;
    }

    const itemsa = [
        <div className="item" key="banner">
            {bannerSlider.banner_image && <img src={baseUrl + bannerSlider.banner_image} alt="Abreco Group" />}
            <div className="slide-content">
                <h2 dangerouslySetInnerHTML={{ __html: bannerSlider.title }}></h2>
                <span>{bannerSlider.sub_title}</span>
            </div>
        </div>
    ];

    const responsivea = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
        1200: { items: 1 },
        1368: { items: 1 },
    };

    const images = [...galleryImages];

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        767: 2
    };

    return (
        <>
            {/* PAGE BANNER */}
            <section className='siteBanner'>
                <AliceCarousel
                    items={itemsa}
                    responsive={responsivea}
                    disableDotsControls
                    disableButtonsControls
                />
                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>

            <section className="galleryLightbox">
                <div className="container">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {images.map((image, index) => (
                            <div key={index} className="gallery-item" onClick={() => openLightbox(index)}>
                                <img src={image.src} alt={`Gallery image ${index + 1}`} />
                            </div>
                        ))}
                    </Masonry>
                    {isOpen && (
                        <Lightbox
                            slides={images}
                            index={photoIndex}
                            open={isOpen}
                            close={() => setIsOpen(false)}
                            onIndexChange={setPhotoIndex}
                            render={{
                                slide: ({ slide }) => (
                                    <div className="lightbox-slide">
                                        <img src={slide.src} alt={slide.caption} />
                                        <div className="lightbox-caption">{slide.caption}</div>
                                    </div>
                                )
                            }}
                        />
                    )}
                </div>
            </section>

            <Footer />

            {bannerSlider && (
                <Helmet key={bannerSlider.uuid}>
                    {bannerSlider.seo_title !== null && <title>{bannerSlider.seo_title}</title>}
                    {bannerSlider.seo_description !== null && (
                        <meta name="description" content={bannerSlider.seo_description} />
                    )}
                    {bannerSlider.seo_keyword !== null && (
                        <meta name="keywords" content={bannerSlider.seo_keyword} />
                    )}
                </Helmet>
            )}
        </>
    );
};

export default Gallery;