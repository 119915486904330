import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import './news.css';

const News = () => {

    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [newsSlider, setNewsSlider] = useState({});
    const [newsList, setNewsList] = useState({});
    const [loading, setLoading] = useState(true);

    const [blogLists, setBlogLists] = useState([]);
    const [blogging, setBlogging] = useState([]);
    const navigate = useNavigate();
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        const newsSliderApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/news"
                );

                const newsSlider = data.data.news || {};
                setNewsSlider(newsSlider);

                const newsList = data.data.news_list || {};
                setNewsList(newsList);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        newsSliderApi();
    }, []);

    useEffect(() => {
        const blogMain = async () => {
            const { data } = await axios.get(
                "https://backend.abrecogroup.com/api/news"
            );
            const blogLists = data.data.news_list;
            setBlogLists(blogLists);
            const blogging = data.data.news_list;
            setBlogging(blogging);
        };
        blogMain();
        setContentLoaded(true);
    }, []);

    const handleBlogClick = (list) => {
        navigate(`/news/${list.slug}`, {});
    };


    if (loading) {
        return <div></div>;
    }

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    return (
        <section className='pageTitle news'>
            <div className='container'>
                <div className='data' data-aos="fade-up">
                    <div className="dots dot-pattern-ten-six xl">
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                    </div>
                    <p>Recent Updates</p>
                    <h2>OUR <span>NEWS</span></h2>
                </div>

                <div className='newsBoxes' data-aos="fade-up">
                    {newsList.slice(0, 3).map((list, index) => (  // This limits to 3 items
                        <div className='item' key={index}>
                            <div>
                                <img src={baseUrl + list.image} alt="Abreco Group" />
                            </div>
                            <h5 dangerouslySetInnerHTML={{ __html: list.title }}></h5>
                            <div onClick={() => handleBlogClick(list)} className="link-whole" rel="noopener noreferrer">CONTINUE</div>
                        </div>
                    ))}
                </div>
                <div className='allNews' data-aos="fade-up">
                    <Link to="/news">All News</Link>
                </div>
            </div>
        </section>
    )
}

export default News