import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import './lifeat.css';

const LifeAt = () => {

  const baseUrl = "https://backend.abrecogroup.com/storage/";
  const [lifeATAbreco, setLifeAtAbreco] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const lifeATAbrecoApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.abrecogroup.com/api/home"
        );

        const lifeATAbreco = data.data.life || {};
        setLifeAtAbreco(lifeATAbreco);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    lifeATAbrecoApi();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <section className='lifeAt'>
      <div className='container'>
        <div className='data'>
            {lifeATAbreco?.title ? (
              <>
                <p dangerouslySetInnerHTML={{ __html: lifeATAbreco.sub_title }} data-aos="fade-up"></p>
                <h2 dangerouslySetInnerHTML={{ __html: lifeATAbreco.title }} data-aos="fade-up"></h2>
              </>
            ) : (
              <p></p>
            )}
          <div id="videoWrapper" data-aos="fade-up">
            {lifeATAbreco?.image ? (
              <>
                <video
                  width="60%"
                  height="100%"
                  poster={baseUrl + lifeATAbreco.image}
                  autoPlay
                  muted
                  loop
                >
                  <source src={lifeATAbreco.video_url} type="video/mp4" />
                  <source src={lifeATAbreco.video_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </>
            ) : (
              <p></p>
            )}

          </div>
            {lifeATAbreco?.image ? (
              <>
                <Link to="/gallery" data-aos="fade-in">OUR GALLERY</Link>
              </>
            ) : (
              <p></p>
            )}
        </div>
      </div>
    </section>
  )
}

export default LifeAt