import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";
import Accordion from 'react-bootstrap/Accordion';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './lifeatabreco.css';

const LifeAtAbreco = () => {

  // useScrollToHash();

  const baseUrl = "https://backend.abrecogroup.com/storage/";
  const [bannerSlider, setLifeAtAbrecoSlider] = useState({});
  const [pageContent, setPageContentSlider] = useState({});
  const [valuesSetting, setValuesSettingSlider] = useState({});
  const [valueList, setValueList] = useState({});
  const [workSettings, setworkSettings] = useState({});
  const [workList, setWorkList] = useState({});
  const [benefitSettings, setBenefitSettings] = useState({});
  const [benefitList, setBenefitList] = useState({});
  const [testimonials, setTestimonials] = useState({});
  const [videoSettings, setVideoSettings] = useState({});
  const [videoTestimonials, setVideoTestimonials] = useState({});
  const [loading, setLoading] = useState(true);
  const iframeRefs = useRef([]);

  useEffect(() => {
    const bannerSliderApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.abrecogroup.com/api/life-at-abreco"
        );

        const bannerSlider = data.data.banner || {};
        setLifeAtAbrecoSlider(bannerSlider);

        const pageContent = data.data.content || {};
        setPageContentSlider(pageContent);

        const valuesSetting = data.data.value_settings || {};
        setValuesSettingSlider(valuesSetting);
        const valueList = data.data.value_list || {};
        setValueList(valueList);

        const workSettings = data.data.work_settings || {};
        setworkSettings(workSettings);
        const workList = data.data.work_list || {};
        setWorkList(workList);

        const benefitSettings = data.data.Benefit_settings || {};
        setBenefitSettings(benefitSettings);
        const benefitList = data.data.Benefit_list || {};
        setBenefitList(benefitList);

        const testimonials = data.data.testimonials || {};
        setTestimonials(testimonials);

        const videoSettings = data.data.vedio_section || {};
        setVideoSettings(videoSettings);
        const videoTestimonials = data.data.video_testimonial || {};
        setVideoTestimonials(videoTestimonials);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    bannerSliderApi();
  }, []);

  if (loading) {
    return <div></div>;
  }


  // Handle when slide is changed
  const onSlideChanged = ({ item }) => {
    iframeRefs.current.forEach((iframe, index) => {
      if (index !== item && iframe) {
        const iframeSrc = iframe.src;
        iframe.src = ""; // Clear iframe src to stop the video
        iframe.src = iframeSrc; // Reassign the src to reset the iframe
      }
    });
  };

  const workListItems = workList.map((list, index) => (
    <div className="item" key={index}>
      <div>
        <h4>{list.title}</h4>
        <p dangerouslySetInnerHTML={{ __html: list.content }}></p>
      </div>
      <img src={baseUrl + list.image} alt={list.title} />
    </div>
  ));

  const responsive = {
    0: { items: 1 }, 568: { items: 1 }, 1024: { items: 1 }, 1200: { items: 1 }, 1368: { items: 1 },
  };

  const itemsa = [
    <div className="item">
      <img src={baseUrl + bannerSlider.image} alt="Abreco Group" />
      <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: bannerSlider.title }}></h2><span>{bannerSlider.sub_title}</span></div>
    </div>
  ];

  const responsivea = {
    0: { itemsa: 1 },
    568: { itemsa: 1 },
    1024: { itemsa: 1 },
    1200: { itemsa: 1 },
    1368: { itemsa: 1 },
  };

  const itemsTestimonial = testimonials.map((list, index) => (
    <div className="item" key={index}>      
      <img src={baseUrl + list.image} alt="Abreco Group" />
      <p dangerouslySetInnerHTML={{ __html: list.description }}></p>
      <h4 dangerouslySetInnerHTML={{ __html: list.title }}></h4>
      <small dangerouslySetInnerHTML={{ __html: list.position }}></small>
    </div>
  ));

  const itemsVideoTestimonial = videoTestimonials.map((list, index) => (
    <div className="item">
       {/* <iframe width="100%" height="600" src={`${list.video_link}?mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

       <iframe
        ref={(el) => (iframeRefs.current[index] = el)}
        width="100%"
        height="600"
        src={list.video_link}
        title={`Video ${index + 1}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  ));

  return (
    <>

      {/* PAGE BANNER */}
      <section className='siteBanner'>
        <AliceCarousel
          items={itemsa}
          responsive={responsivea}
          disableDotsControls
          disableButtonsControls
        />
        <div className='slanding1'>
          <SimpleAnimatedComponent animation="animate__fadeInUpBig">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
        <div className='slanding2'>
          <SimpleAnimatedComponent animation="animate__fadeInUpBig">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
      </section>

      {/* PAGE SUBTITILE */}
      <section className='pageSubTitle lifePageTitle'>
        <div className='container'>
          <div className='data' data-aos="fade-up">
            <div id="dot-1">

              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div>
              {pageContent?.title ? (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: pageContent.title }}></h2>
                  <p dangerouslySetInnerHTML={{ __html: pageContent.content }}></p>
                </>
              ) : (
                <p></p>
              )}
          </div>
        </div>
      </section>

      {/* VALUES AND LIST */}
      <section className='pageSubTitle pb-0'>
        <div className='container'>
          <div className='data no-padding' data-aos="fade-up">
              <p>Our</p>
              {valuesSetting?.title ? (<h2>{valuesSetting.title}</h2>) : (<p></p>)}
          </div>
        </div>
      </section>
      <section className="icon-box content-image">
        <div className="container">
          <div className="data">
            <div className="image" data-aos="fade-up">
              {valuesSetting?.image ? (<img src={baseUrl + valuesSetting.image} alt={valuesSetting.title} />) : (<p></p>)}
            </div>
            <div className="content pageContent" data-aos="fade-up">
              <Accordion defaultActiveKey={0}>
                {valueList.map((list, index) => (
                  <>
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{list.title}</Accordion.Header>
                      <Accordion.Body>{list.content}</Accordion.Body>
                    </Accordion.Item>
                  </>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      {/* PRICIPLES AND LIST */}
      <section className='pageSubTitle pb-0'>
        <div className='container'>
          <div className='data no-padding' data-aos="fade-up">
              {workSettings?.sub_title ? (<p>{workSettings.sub_title}</p>) : (<p></p>)}
              {workSettings?.title ? (<h2>{workSettings.title}</h2>) : (<p></p>)}
          </div>
        </div>
      </section>
      <section className="icon-box content-image">
        <div className="container">
          <div className="data flex-direction-column">
            {/* <div className="image" data-aos="fade-up">
              {workSettings?.image ? (<img src={baseUrl + workSettings.image} alt={workSettings.title} />) : (<p></p>)}
            </div> */}
            <div className="content pageContent workCarousel" data-aos="fade-up">
              {workSettings?.content ? (<p>{workSettings.content}</p>) : (<p></p>)}
              <AliceCarousel
                // mouseTracking
                items={workListItems}
                autoPlay
                autoPlayInterval={5000}
                infinite
                // disableButtonsControls
                autoPlayStrategy="none"
                stopOnHover={false}
                responsive={responsive}
              />
            </div>
          </div>
        </div>
      </section>

      {/* BENEFIT AND LIST */}
      <section className='pageSubTitle benefitsList'>
        <div className='container'>
          <div className='data no-padding' data-aos="fade-up">
              {benefitSettings?.content ? (<p dangerouslySetInnerHTML={{ __html: benefitSettings.content }}></p>) : (<p></p>)}
              {benefitSettings?.title ? (<h2 dangerouslySetInnerHTML={{ __html: benefitSettings.title }}></h2>) : (<p></p>)}
              <ul className="gridBox">
                {benefitList.map((list, index) => (
                  <>
                    <li className='item' key={index}>
                      <div className="img"><img src={baseUrl + list.image} alt={list.title} /></div>
                      <p>{list.title}</p>
                    </li>
                  </>
                ))}
              </ul>
          </div>
        </div>
      </section>

      {/* CALL TO ACTION WITH BACKGROUND */}
      <section className='pageSubTitleWithBanner brandsCTA' data-aos="fade-up">
        <div className='container' style={{
            backgroundImage: `url(${baseUrl}${bannerSlider.image})`,
        }}>
            <div className='data'>
                    <p>JOIN ABRECO</p>
                    <h2>LEARN FROM THE BEST</h2>
                    <Link to="/careers">JOIN THE ABRECO FAMILY TODAY!</Link>
            </div>
        </div>
      </section> 

      <div id="abrecoTestimonials" style={{
        top: '-70px',
        position: 'relative'
      }}></div>

      <section className="abrecoTestimonials">
        <div className="container">
          <div className="data">
            {/* <div className="textTestimonials" id="testimonials" data-aos="fade-up">
              <AliceCarousel
                items={itemsTestimonial}
                responsive={responsivea}
                // disableDotsControls
                disableButtonsControls
                mouseTracking
                autoPlay
                autoPlayInterval={9000}
                infinite
              />
            </div> */}
            <div className="videoTestimonials" id="video-testimonials" data-aos="fade-up">
              <AliceCarousel
                items={itemsVideoTestimonial}
                responsive={responsivea}
                // disableDotsControls
                disableButtonsControls
                mouseTracking
                // autoPlay
                // autoPlayInterval={4000}
                infinite
                onSlideChanged={onSlideChanged}
              />
            </div>
            <div className="link" data-aos="fade-in">
              <Link to="/gallery">Image Gallery</Link>
            </div>
          </div>
        </div>
      </section>

      {/* CONTENT WITH VIDEO */}
      <section className="half-half content-image">
        <div className="container">
          <div className="data">
            <div className="image" data-aos="fade-up">
              <video width="100%" height="100%" poster={baseUrl + videoSettings.thumbnail} autoPlay muted loop>
                <source src={videoSettings.video_link} type="video/mp4" />
                <source src={videoSettings.video_link} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="content pageContent" data-aos="fade-up">
              {videoSettings?.title ? (<h2 dangerouslySetInnerHTML={{ __html: videoSettings.title }}></h2>) : (<p></p>)}
              {videoSettings?.content ? (<p dangerouslySetInnerHTML={{ __html: videoSettings.content }}></p>) : (<p></p>)}
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {pageContent && (
        <Helmet key={pageContent.uuid}>
          {pageContent.seo_title !== null && <title>{pageContent.seo_title}</title>}
          {pageContent.seo_description !== null && (
            <meta name="description" content={pageContent.seo_description} />
          )}
          {pageContent.seo_keyword !== null && (
            <meta name="keywords" content={pageContent.seo_keyword} />
          )}
        </Helmet>
      )}

    </>
  )
}

export default LifeAtAbreco